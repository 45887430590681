import { lazy, Suspense } from 'react';

const Home = lazy(() => import('../pages/Home'));
const AboutUs = lazy(() => import('../pages/AboutUs'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const Terms = lazy(() => import('../pages/Terms'));

export const HomeRoutes = [
  {
    index: true,
    element: (
      <Suspense fallback={<></>}>
        <Home />
      </Suspense>
    ),
  },
  {
    path: 'about-us',
    element: (
      <Suspense fallback={<></>}>
        <AboutUs />
      </Suspense>
    ),
  },
  {
    path: 'privacy-policy',
    element: (
      <Suspense fallback={<></>}>
        <PrivacyPolicy />
      </Suspense>
    ),
  },
  {
    path: 'terms',
    element: (
      <Suspense fallback={<></>}>
        <Terms />
      </Suspense>
    ),
  },
];
