import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';

import { AppRoutes } from './routes';
import { persistor, store } from './store';
import theme from './themes/theme';

import Header from './components/Header';
import Footer from './components/Footer';

import CheckoutRoutes from './routes/checkoutRoutes';

export const BaseLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          {children}
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

const Layout = () => {
  return (
    <BaseLayout>
      <Header />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '90vh',
        }}
      >
        <Box
          component="main"
          sx={{
            flex: 1,
          }}
        >
          <Outlet />
        </Box>

        <Footer />
      </Box>
    </BaseLayout>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: AppRoutes,
  },
  CheckoutRoutes,
]);

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
