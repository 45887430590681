import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';
import {
  ABOUT_US_TITLE,
  PRIVACY_POLICY_TITLE,
  TERMS_AND_CONDITIONS_TITLE,
  COPYRIGHT_TEXT,
} from '../../constants/strings.constant';
import { baseRoutes } from '../../constants/routes.constants';

export default function Footer() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.5rem 3.75rem',
        backgroundColor: 'secondary.contrastText',
        color: '#fff',
        width: '100%',
        margin: '0',
        bottom: 0,
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1.5rem'
        }}>
        <Link
          component={RouterLink}
          to={baseRoutes.aboutUs}
          color="inherit"
          underline="none"
          variant="body2"
        >
          {ABOUT_US_TITLE}
        </Link>
        <Link
          component={RouterLink}
          to={baseRoutes.terms}
          color="inherit"
          underline="none"
          variant="body2"
        >
          {TERMS_AND_CONDITIONS_TITLE}
        </Link>
        <Link
          component={RouterLink}
          to={baseRoutes.privacyPolicy}
          color="inherit"
          underline="none"
          variant="body2"
        >
          {PRIVACY_POLICY_TITLE}
        </Link>
      </Box>

      <Typography variant="body2" color="inherit">
        {COPYRIGHT_TEXT}
      </Typography>
    </Box>
  );
}
